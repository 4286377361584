import "../styles/styles.css";

import * as React from "react";
import SeasonPage from "../components/seasonPage.component";

// markup
const SeasonOnePage = () => {
  return <SeasonPage seasonId={1}></SeasonPage>;
};

export default SeasonOnePage;
